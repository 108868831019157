import React from "react";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import SEO from "../../components/seo";

const lunchevent = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA & Symrise Host a Festive Year-End Lunch 2023"
          description="Celebration and Solidarity"
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              ></div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Celebration and Solidarity: SWA & Symrise Host a Festive
                    Year-End Lunch 2023
                  </span>
                </h1>
                {/* <h2 className="my-3 text-center">
                  Joint project by Singapore Women Association(SWA) and <br />
                  Symrise Asia Pacific Pte Ltd
                </h2> */}
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Singapore Women's Association (SWA), in partnership with
                  Symrise Asia Pte Ltd, hosted a festive lunch at Safra Toa
                  Payoh on December 2, 2023. The event was a harmonious blend of
                  fun, performances, and community outreach, bringing together
                  families from Star Shelter, Anglican Family Centre, and Casa
                  Raudha Ltd.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705584/SymriseLunchEvent2023/jb5ccvjn1smshgmted0r.webp"
                    alt="Year-End Lunch Event 2023"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The event kick-started with setting up game booths and DIY
                  keychain corners, masterfully arranged by eighteen Symrise
                  volunteers. Angeline Yang, SWA board member, took charge of
                  decoration with help of all volunteers. A joyful Christmas
                  atmosphere pervaded the hall, where balloons hung in splendid
                  array. With each child receiving three tickets to enjoy the
                  games, the atmosphere was exhilarating and anticipating.
                </p>
                <div className="w-full mx-auto my-2 mt-8">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705583/SymriseLunchEvent2023/dtwjalywtfxw9fr8vlgr.webp"
                    alt="Year-End Lunch Event"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705584/SymriseLunchEvent2023/wvfteniljjg0zya9nd5l.webp
                    "
                    alt="Year-End Lunch Event"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  As the clock struck 11:30 am, the arrival of residents and
                  guests marked the official beginning of the event. Siew Lin
                  from SWA, alongside Symrise staff, had prepared 60 small
                  prizes for each booth, setting the stage for an enthralling
                  day ahead.
                </p>

                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-3 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705577/SymriseLunchEvent2023/ayfkiebxjlzjya6vseoh.webp"
                          alt="Laravel"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705582/SymriseLunchEvent2023/z6fbxbyunm37yru59bp2.webp"
                          alt="SWA - Symrise lunch event 2024"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705582/SymriseLunchEvent2023/q0zr9ontbw2ylvy8qgyu.webp"
                          alt="SWA - Symrise lunch event 2024"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-3 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705582/SymriseLunchEvent2023/zmmnrcpg7pi0oi2bozux.webp"
                          alt="SWA - Symrise lunch event 2024"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705581/SymriseLunchEvent2023/f7iqxpzanqsbsfzmqwxy.webp"
                          alt="SWA - Symrise lunch event 2024"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className="object-cover"
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705581/SymriseLunchEvent2023/onbvo9zswakq9woizq88.webp"
                          alt="SWA - Symrise lunch event 2024"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Laughter yoga exercises led by Angela Wong, Sara Mei Woo, and
                  Feng Fang from SWA's Laughter Yoga and Wellness Club commenced
                  at 11:50 am, filling the venue with joyful chuckles. Lee Li
                  Hua, President of the Singapore Women's Association and Audrey
                  Heng from the Symrise volunteer committee gave their welcome
                  speech.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705581/SymriseLunchEvent2023/tqsl7rtwv4txxn22c0vc.webp"
                          alt="Lee Li Hua"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705580/SymriseLunchEvent2023/c45o1x6lowgzyzvjwvqe.webp"
                          alt="Audrey Heng"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705579/SymriseLunchEvent2023/venqlvqydzh5pyujxcjm.webp"
                          alt="Laughter Yoga"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705579/SymriseLunchEvent2023/mg0csax2b3xuq2k12msx.webp"
                          alt="Laughter Yoga"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Luncheon began at noon
                </p>
                <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                  <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                    <img
                      className=""
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705579/SymriseLunchEvent2023/dv6uaccgcshpgcbn8ec3.webp"
                      alt="Lunch"
                    />
                  </div>
                  <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                    <img
                      className=""
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705578/SymriseLunchEvent2023/clbaarjfni3c2qxrruth.webp"
                      alt="Lunch"
                    />
                  </div>
                  <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                    <img
                      className=""
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705578/SymriseLunchEvent2023/fevbwhnjx7nbfecjgfj2.webp"
                      alt="Lunch"
                    />
                  </div>
                  <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                    <img
                      className=""
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705578/SymriseLunchEvent2023/qd7g1cxheed5f6yxjyft.webp"
                      alt="Lunch"
                    />
                  </div>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Monica Sham delivered an impressive drama performance,
                  captivating the audience with her skills. This was followed by
                  heartwarming performances from the children of Star Shelter
                  and Anglican Family Centre, who brought joy to the event with
                  their dances and songs.
                </p>
                <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                  <img
                    className=""
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705578/SymriseLunchEvent2023/atgch2ntc4b0v0gkgrfm.webp"
                    alt="Monica Sham"
                  />
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Children from Star Shelter and Anglican Family Centre each
                  gave wonderful performances at the event. Their presentations
                  included a mix of dances and musical numbers, adding a lot of
                  fun and heart to the festivities.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705578/SymriseLunchEvent2023/slf8c8nxq3rmfk5lfjoc.webp"
                          alt="Children performances"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705577/SymriseLunchEvent2023/fodvc0x0chyyniuulqx8.webp"
                          alt="Children performances"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705577/SymriseLunchEvent2023/potqgjwt9ceoawmfiqq6.webp"
                          alt="Children performances"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705577/SymriseLunchEvent2023/fhbjemxprylgem0v5lhe.webp"
                          alt="Children performances"
                        />
                      </div>
                    </div>
                  </div>
                </div>
          
                {/* <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1670239478/SymriseLunchEvent/ao2jrjo0jwexxxfwjnxi.webp"
                    alt="Miss Singapore International and Miss Singapore Supranational Queens as MC"
                    className="w-full mx-auto "
                  />
   
                </div> */}
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The event was made even more special with the participation of
                  past queens from the Miss Singapore Pageant International.
                  They played a big role in the day's activities, with Roxanne
                  Zhang, Miss Singapore International 2015, expertly handling
                  the MC duties and Charlotte Chia, Miss Singapore International
                  2019, and Lynette Lee, Miss Singapore International 1995,
                  helping to run the fun stage games. Their involvement added a
                  unique charm to the event and showed their commitment to
                  giving back to the community.
                </p>
                <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                  <img
                    className=""
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705576/SymriseLunchEvent2023/h3f56sswg7ncjcdx2tya.webp"
                    alt="Roxanne Zhang and Charlotte Chia"
                  />
                </div>
   
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Post-lunch, the festivities continued with engaging activities
                  such as the '1 minute of fame' and 'Guess the number' stage
                  games. The lucky draw segment, led by Roxanne Zhang , and the
                  draw conducted by Lee Li Hua, SWA President, was a moment of
                  high anticipation and joy.
                </p>
                <div className="bg-white">
                  <div className="py-1 mx-auto max-w-7xl ">
                    <div className=" grid grid-cols-1 gap-0.5 md:grid-cols-2 ">
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705577/SymriseLunchEvent2023/jv3vic0pfqvzyd2hv3kq.webp"
                          alt="Post-Lunch"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705576/SymriseLunchEvent2023/gk3bilasrcrffa9grteh.webp"
                          alt="Post-Lunch"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705577/SymriseLunchEvent2023/ayfkiebxjlzjya6vseoh.webp"
                          alt="Post-Lunch"
                        />
                      </div>
                      <div className="flex justify-center col-span-1 px-1 py-1 bg-gray-50">
                        <img
                          className=""
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705576/SymriseLunchEvent2023/c8cuw0jvq9o3dtvxonzl.webp"
                          alt="Post-Lunch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                


       
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The event ended at 2:30 pm, with volunteers assisting as
                  guests departed. They left a lasting impression, filled with
                  laughter, camaraderie, and a renewed sense of community.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705576/SymriseLunchEvent2023/amkkeofdyytecoihcq7e.webp"
                    alt="Thank you"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA volunteers: Lee Li Hua, Angela Wong, Lynette Lee, Angeline
                  Yang, Siew Lin, Betty Ho, Serene Tan, Feng Fang, Roxanne
                  Zhang, Charlotte Chia, Sara Mei Woo, Monica Sham.
                </p>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Symrise volunteers: May Chua, Aricen Ho, Sooyoung Ahn, April
                  Tong,, Linda Chan, Cheng Kwee Wee, Kwie Cher Wee, Rachel Ku,
                  Fong Ming Hong, Tao Yuting, Priscilla Ramnath, Wan Mun Yee,
                  Audrey Heng, Juliana Khatmin, Daavena Ramani, Xiao Ping Tan,
                  Ethan Koh.
                </p>

                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1702705576/SymriseLunchEvent2023/ikkjz3simvawcdcbo5jn.webp"
                    alt="SWA and Symrise committee and volunteers"
                    className="w-full mx-auto "
                  />
                  <p className="text-sm text-left text-gray-600 ">
                    Organising committee members and volunteers from SWA and
                    Symrise
                  </p>
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Learn more about{" "}
                  <a
                    href="/how/rebuild"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold text-swa-1"
                  >
                    Project Rebuild
                  </a>
                </p>

                <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                  <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                    <span className="block">
                      Is your organisation looking to support and sponsor our
                      initiatives?
                    </span>
                    <span className="block text-swa-1">Let's discuss.</span>
                  </h2>
                  <div className="flex mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <Link
                        to="/forms/contact"
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default lunchevent;
